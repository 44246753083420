<template>
  <div class="password">
    <h2 class="password__title tw-text-xl tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900">
      {{ $t('account.password.title') }}
    </h2>

    <form
      class="password__form tw-w-full"
      :disabled="$wait.is('updating password')"
      @submit.prevent="submitted"
    >
      <p
        class="password__form__password-explanation tw-italic tw-leading-tight"
        data-test="password-explanation"
      >
        {{ $t('account.password.paragraphs.password') }}
      </p>

      <ValidationObserver
        ref="observer"
      >
        <template slot-scope="{ dirty }">
          <ValidationProvider
            ref="current_password-provider"
            :name="$t('account.password.fields.actual_password')"
            rules="required|min:6"
            slim
          >
            <template slot-scope="{ invalid, validated, errors }">
              <ctk-input-text
                id="actualPassword"
                v-model="formData.actualPassword"
                :label="$t('account.password.fields.actual_password') | capitalize"
                :disabled="$wait.is('updating password')"
                :error="invalid && validated"
                :hint="errors[0]"
                type="password"
                name="actualPassword"
                class="tw-mb-2"
                required
              />
            </template>
          </ValidationProvider>
          <ValidationProvider
            vid="password"
            ref="new_password-provider"
            :name="$t('account.password.fields.password')"
            rules="required|min:6"
            slim
          >
            <template slot-scope="{ invalid, validated, errors }">
              <ctk-input-text
                ref="password"
                id="password"
                v-model="formData.password"
                :label="$t('account.password.fields.password') | capitalize"
                :disabled="$wait.is('updating password')"
                :error="invalid && validated"
                :hint="errors[0]"
                type="password"
                name="password"
                class="tw-mb-2"
                autocomplete="new-password"
                required
              />
            </template>
          </ValidationProvider>
          <ValidationProvider
            :name="$t('account.password.fields.password_confirmation_as')"
            rules="required|min:6|confirmed:password"
            slim
          >
            <template slot-scope="{ invalid, validated, errors }">
              <ctk-input-text
                id="passwordConfirmation"
                v-model="formData.passwordConfirmation"
                :label="$t('account.password.fields.password_confirmation') | capitalize"
                :disabled="$wait.is('updating password')"
                :error="invalid && validated"
                :hint="errors[0]"
                type="password"
                name="passwordConfirmation"
                class="tw-mb-2"
                autocomplete="new-password"
                required
              />
            </template>
          </ValidationProvider>

          <div class="password__form__buttons tw-flex tw-justify-between tw-mt-10">
            <ui-button
              :disabled="!dirty || $wait.is('updating password')"
              variant="link"
              type="reset"
              data-test="cancel-button"
              @click="reset"
            >
              {{ $t('account.buttons.cancel') }}
            </ui-button>
            <ui-button
              :loading="$wait.is('updating password')"
              :disabled="$wait.is('updating password')"
              class="password__form__buttons__save tw-mr-2"
              variant="primary"
              type="submit"
              data-test="save-button"
            >
              {{ $t('account.password.buttons.save') }}
            </ui-button>
          </div>
        </template>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { showToaster } from '@/services/Toaster'
  import store from '@/store'
  import { Me } from '@/resources'
  import handlePropertyPathViolations from '@/resources/handlers/violations'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'

  /**
   * @module view - password
   */
  export default {
    name: 'Password',
    components: {
      CtkInputText
    },
    metaInfo () {
      return {
        title: this.$t('account.password.title')
      }
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)
      next()
    },
    data () {
      return {
        formData: {
          actualPassword: null,
          password: null,
          passwordConfirmation: null
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ])
    },
    methods: {
      submitted () {
        if (this.$wait.is('updating password')) {
          return
        }

        this.$refs.observer.validate()
          .then(valid => {
            if (!valid) {
              return
            }

            this.$wait.start('updating password')
            Me.password({}, {
              current_password: this.formData.actualPassword,
              new_password: this.formData.password
            })
              .then(() => {
                showToaster(
                  this,
                  this.$t('account.password.paragraphs.password_updated'), {
                    type: 'success',
                    position: 'bottom-right'
                  }
                )
                this.reset()
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error && data.error.violations) {
                  handlePropertyPathViolations.call(this, data.error.violations)
                } else {
                  showToaster(
                    this,
                    data.error.title, {
                      type: 'error',
                      position: 'bottom-right'
                    }
                  )
                }
              })
              .finally(() => this.$wait.end('updating password'))
          })
      },
      reset () {
        this.formData = {
          actualPassword: null,
          password: null,
          passwordConfirmation: null
        }
        this.$refs.observer.reset()
      }
    }
  }
</script>

<style lang="scss" scoped>

  .password {
    &__title {
      @media only screen and (max-width: 857px) {
        display: none;
      }
    }
  }

</style>
