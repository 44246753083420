var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"password"},[_c('h2',{staticClass:"password__title tw-text-xl tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900"},[_vm._v(" "+_vm._s(_vm.$t('account.password.title'))+" ")]),_c('form',{staticClass:"password__form tw-w-full",attrs:{"disabled":_vm.$wait.is('updating password')},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('p',{staticClass:"password__form__password-explanation tw-italic tw-leading-tight"},[_vm._v(" "+_vm._s(_vm.$t('account.password.paragraphs.password'))+" ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
return [_c('ValidationProvider',{ref:"current_password-provider",attrs:{"name":_vm.$t('account.password.fields.actual_password'),"rules":"required|min:6","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-2",attrs:{"id":"actualPassword","label":_vm._f("capitalize")(_vm.$t('account.password.fields.actual_password')),"disabled":_vm.$wait.is('updating password'),"error":invalid && validated,"hint":errors[0],"type":"password","name":"actualPassword","required":""},model:{value:(_vm.formData.actualPassword),callback:function ($$v) {_vm.$set(_vm.formData, "actualPassword", $$v)},expression:"formData.actualPassword"}})]}}],null,true)}),_c('ValidationProvider',{ref:"new_password-provider",attrs:{"vid":"password","name":_vm.$t('account.password.fields.password'),"rules":"required|min:6","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{ref:"password",staticClass:"tw-mb-2",attrs:{"id":"password","label":_vm._f("capitalize")(_vm.$t('account.password.fields.password')),"disabled":_vm.$wait.is('updating password'),"error":invalid && validated,"hint":errors[0],"type":"password","name":"password","autocomplete":"new-password","required":""},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('account.password.fields.password_confirmation_as'),"rules":"required|min:6|confirmed:password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-2",attrs:{"id":"passwordConfirmation","label":_vm._f("capitalize")(_vm.$t('account.password.fields.password_confirmation')),"disabled":_vm.$wait.is('updating password'),"error":invalid && validated,"hint":errors[0],"type":"password","name":"passwordConfirmation","autocomplete":"new-password","required":""},model:{value:(_vm.formData.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirmation", $$v)},expression:"formData.passwordConfirmation"}})]}}],null,true)}),_c('div',{staticClass:"password__form__buttons tw-flex tw-justify-between tw-mt-10"},[_c('ui-button',{attrs:{"disabled":!dirty || _vm.$wait.is('updating password'),"variant":"link","type":"reset"},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t('account.buttons.cancel'))+" ")]),_c('ui-button',{staticClass:"password__form__buttons__save tw-mr-2",attrs:{"loading":_vm.$wait.is('updating password'),"disabled":_vm.$wait.is('updating password'),"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('account.password.buttons.save'))+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }